import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserChecksState } from '../reducers/user-checks.reducer';
import { USER_CHECKS_KEY } from '../keys';

const selectState = createFeatureSelector<UserChecksState>(USER_CHECKS_KEY);

const selectLoaded = createSelector(selectState, (state: UserChecksState) => state.loaded);
const selectLoading = createSelector(selectState, (state: UserChecksState) => state.loading);

const selectCanPlaceOrder = createSelector(selectState, (state: UserChecksState) => state.canPlaceOrder);

export { selectLoaded, selectCanPlaceOrder, selectLoading };
