import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap, of } from 'rxjs';
import { UserChecksFacade } from '../facades/user-checks.facade';
import { options } from '@options';

export function userChecksLoaded(): CanActivateFn {
    return () => {
        const userChecksFacade = inject(UserChecksFacade);

        if (options.canOrderLimit !== true) {
            return of(true);
        }

        return userChecksFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    userChecksFacade.getCanPlaceOrder();
                }
            }),
            filter((loaded) => loaded !== null),
            take(1),
            map(() => true),
        );
    };
}
