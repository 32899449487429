import { Component } from '@angular/core';
import { OpenClose, PopupBaseComponent } from '@library';

@Component({
    selector: 'app-can-order',
    templateUrl: './can-order.component.html',
    styleUrls: ['./can-order.component.scss'],
    animations: [OpenClose],
})
export class CanOrderComponent extends PopupBaseComponent {
    override blockEsc = true;

    closePopup() {
        this.close('close');
    }
}
