import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { UserChecksFacade } from '../facades/user-checks.facade';

export function userChecksCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const userChecksFacade = inject(UserChecksFacade);

        userChecksFacade.clear();
        return true;
    };
}
