import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@library';

import { ShipComponent } from './components/ship/ship.component';
import { PriceTotalPipe } from '../cart/pipes/total-price.pipe';
import { LimitComponent } from './components/limit/limit.component';
import { CanOrderComponent } from './components/can-order/can-order.component';

@NgModule({
    declarations: [ShipComponent, LimitComponent, CanOrderComponent],
    imports: [CommonModule, TranslateModule, IconsModule],
    exports: [ShipComponent, LimitComponent, CanOrderComponent],
    providers: [PriceTotalPipe],
})
export class BlockadesModule {}
