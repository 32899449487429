import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { userChecksActions } from '../actions/user-checks.actions';
import { selectLoaded, selectCanPlaceOrder, selectLoading } from '../selectors/user-checks.selector';

@Injectable({
    providedIn: 'root',
})
export class UserChecksFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);
    canPlaceOrder$ = this.store.select(selectCanPlaceOrder).pipe(filter((data) => data !== null));

    getCanPlaceOrder(): void {
        this.store.dispatch(userChecksActions.getCanPlaceOrder());
    }

    clear(): void {
        this.store.dispatch(userChecksActions.clear());
    }
}
