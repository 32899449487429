import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CanPlaceOrderModel } from '../models';

export const userChecksActions = createActionGroup({
    source: 'UserChecks',
    events: {
        'Get Can Place Order': emptyProps(),
        'Get Can Place Order Success': props<{ canPlaceOrder: CanPlaceOrderModel }>(),
        'Get Can Place Order Error': emptyProps(),

        Clear: emptyProps(),
    },
});
