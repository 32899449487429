import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UserBlocked } from '@app/store/models';
import { BlockadesService } from '../../services/blockades.service';
import { Grow } from '@library';
import { BehaviorSubject, Subscription, filter, map, tap } from 'rxjs';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-ship',
    templateUrl: './ship.component.html',
    styleUrls: ['./ship.component.scss'],
    animations: [Grow],
})
export class ShipComponent implements OnInit, OnDestroy {
    private readonly userFacade = inject(UserFacade);
    private readonly blockadesService = inject(BlockadesService);
    readonly type = 'ship';

    setStorageKey$ = new BehaviorSubject<{ type: UserBlocked | null; id: string | null }>({
        id: null,
        type: null,
    });
    blocked$ = this.userFacade.blocked$.pipe(
        tap((type) =>
            this.setStorageKey$.next({
                ...this.setStorageKey$.value,
                type,
            }),
        ),
    );

    isLimited$ = this.userFacade.user$.pipe(map((user) => user.roles.includes('ROLE_USER_LIMITED')));
    id$ = this.userFacade.user$.pipe(
        map((user): string => user.salespersonInfo?.no ?? ''),
        tap((id) =>
            this.setStorageKey$.next({
                ...this.setStorageKey$.value,
                id,
            }),
        ),
    );

    private readonly subscriptions$ = new Subscription();

    get isClosed() {
        return this.blockadesService.isClosed;
    }

    ngOnInit(): void {
        this.subscriptions$.add(
            this.setStorageKey$
                .pipe(
                    filter((value): value is { type: UserBlocked; id: string } => value.id !== null && value.type !== null),
                    tap(({ type, id }) => this.blockadesService.setStorageKey(type, id)),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    close() {
        this.blockadesService.close();
    }
}
